.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}
.portfolio__items {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.portfolio__items:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}
.portfolio__item {
    position: relative;
    padding-bottom: 2.5rem;
}
.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 1rem;
}

.portfolio__item-cta {
    position: absolute; /* Positions the element absolutely within its parent */
    bottom: 0.5rem; /* Adjust as needed to position the button from the bottom */
    width: 100%; /* Ensure the width matches the parent */
    display: flex;
    justify-content: left; /* Centers the button */
    gap: 2rem; /* Keep your existing gap if needed */
}

.portfolio__item p {
    margin-bottom: 2rem;
}

/* ############# MEDIA QUERIES (MEDIUM DEVICES #############*/
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ############# MEDIA QUERIES (SMALL DEVICES #############*/
@media screen and (max-width: 600px) {
    .experience__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}