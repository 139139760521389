header{

    padding-top: 4rem;
    overflow: hidden;
}

.header-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
    color: var(--color-light); /* Add this line to set the icon color */
    font-size: 1.3rem;
  }

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

/* ################# CTA ############### */
.cta {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    justify-content: center;
}
/* ################# HEADER SOCIALS ############### */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 10rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ############# ME ################ */
.me {
    display: flex;
    width: 30rem;
    aspect-ratio: 1.5;
    position: relative;
    left: calc(50% - 15rem);
    margin-top: 3rem;
    border-radius: 3rem; 
    overflow: hidden;

  }
  

/* ########### SCROLL DOWN ############# */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 15rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;

}
/* ######### cutting issue #############
@media screen and (max-height: 800px) {
    header{
        height: 75vh;
    }
    .me {
        width: 30rem;
        height: 8rem; 
        position: absolute;
        left: calc(50% - 10rem);
        margin-top: 3rem;
        border-radius: 3rem; 
        overflow: hidden;
    
      }
}
*/
/* ############# MEDIA QUERIES (MEDIUM DEVICES #############*/
@media screen and (max-width: 1024px) {
    header{
        height: 75vh;
    }
    .me {
        width: 20rem;
        height: 13rem; /* Adjust the height to match the aspect ratio of your image */
        position: absolute;
        left: calc(50% - 10rem);
        margin-top: 3rem;
        border-radius: 3rem; /* Change the border-radius to make all four corners rounded */
        overflow: hidden;
    
      }
}

/* ############# MEDIA QUERIES (SMALL DEVICES #############*/
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }
    .me {
        width: 20rem;
        height: 13rem; /* Adjust the height to match the aspect ratio of your image */
        position: absolute;
        left: calc(50% - 10rem);
        margin-top: 5rem;
        border-radius: 3rem; /* Change the border-radius to make all four corners rounded */
        overflow: hidden;
    
      }
}