nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: flex;
    justify-content: center;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem;
    display: flex;
    gap: 1.5rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);

}

nav a {
    background: transparent;
    padding: 0.2rem;
    border-radius: 50%;
    display: flex;
    align-items: center; 
    font-size: 1.5rem;
    flex-direction: column;
    align-items: center;
    color: var(--color-white);

}



nav a.active {

    color: var(--color-white);
}

.nav__label {
    font-size: 0.8rem; /* Adjust the font size as desired */
    margin-top: 0.3rem; /* Add spacing between the icon and label */
    color: var(--color-white);
    opacity: 0.5;
  }

  nav a:hover .nav__label {
    opacity: 1; /* Set the opacity to 1 on hover */
  }

 /* nav a.active .nav__label {
    opacity: 1; 
  }
  */
  /* figure out how to change opacity as user scrolls*/