@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #fcfcfc;
    --color-bg-variant: #285e66;
    --color-primary: #7db7e1;
    --color-primary-variant: rgba(120, 221, 247, 0.621);
    --color-white: #fff;
    --color-light: rgba(255,255,255,0.6);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;

}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    /*background: rgba(0, 0, 0, 0.555); */
    /*background-image: url(../src/assets/wb bg 1.jpg);*/
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)), url(../src/assets/bg1.jpg);
    background-repeat: repeat;
    background-size: cover; /* Add this line to control the size of the background image */
    background-attachment: fixed; /* Add this line to fix the background image */
}

/* ################### GENERAL STYLES ######## */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 600;
}

h1 {
    font-size: 2.5rem;
}


section {
    margin-top: 8rem;
    margin-bottom: 3rem;
}

section > h2 {
    text-align: center;
    color: var(--color-light);
}

section > h5 {
    text-align: center;
    color: rgba(255, 255, 255, 0.849);
}

section > h4 {
    text-align: center;
    color: rgba(255, 255, 255, 0.849);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white)
}

.btn {
    width: max-content;
    display: inline-block;
    color: rgba(255,255,255,0.8);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-primary-variant);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
background: var(--color-primary);
color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ############# MEDIA QUERIES (MEDIUM DEVICES #############*/
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* ############# MEDIA QUERIES (SMALL DEVICES #############*/
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section >h2 {
        margin-bottom: 2rem;
    }
}