footer {
    background: transparent;
    padding: 5rem 0;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 3rem;

}

footer a{
    color: var(--color-bg);
}

.footer__logo {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: inline-block;

}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;

}

